import React from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  height?: number;
  width?: number;
}

const MinusIcon = ({ height = 20, width = 20 }: Props) => {
  const { themeName } = useThemeContext();
  const isExpThemeFromContext = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !(isExpThemeFromContext) ? (
        <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_24056_3542)">
            <path d="M15.8337 10.7332H4.16699V9.1665H15.8337V10.8332Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_24056_3542">
              <rect width={width} height={height} fill="white"/>
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_1244_7397)">
            <path d="M19 13H5V11H19V13Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_7397">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default MinusIcon;